.captcha-form-group {
    
    .col.col-captcha-widget {
        -ms-flex: 0 0 200px;
        flex: 0 0 200px;
    }
    
    input.form-control {
        display: block;
        width: 200px;
        margin-top: 10px;
    }

    img.captcha_image {
        display: inline-block;
        margin-right: 10px;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        /*box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);*/
    }

    a.captcha_reload {
        display: inline-block;
        padding-left: 30px;
        background-image: url(../images/reload_icon_20x20.png);
        background-repeat: no-repeat;
        background-position: left;
        margin-top: 10px;
    }
    
    a.captcha_reload:hover {
        background-image: url(../images/reload_icon_light_20x20.png);
    }

}