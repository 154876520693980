.container.main-container {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    position: relative;
    background: #f9f9f9;
    padding: 25px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

@media (max-width: 600px) {
    .container.main-container {
        margin-top: 0;
    }
}

.container.main-container footer.page-footer {
    margin: 0 -25px -25px -25px;
}

.container.main-container nav.page-navbar {
    margin: -25px -25px 0 -25px;
}

footer.page-footer,
nav.page-navbar {
    border: 1px solid #f9f9f9;
    border-radius: .25rem;
}

@media (max-width: 600px) {
    footer.page-footer,
    nav.page-navbar {
        border: none;
        border-radius: 0;
    }
}

footer.page-footer {
    color: #fff;
}

footer.page-footer a {
    color: #beedcf; /*#d1ecf1;*/ /*#b9efcb;*/
}

nav.page-navbar .navbar-brand .logo {
    width: 52px;
    height: 30px;
    background-image: url(../images/MIS-logo-52x30.png);
    /*width: 70px;
    height: 40px;
    background-image: url(../images/MIS-logo-70x40.png);*/
    background-repeat: no-repeat;
    background-position: left;
}

#navbarNavDropdown {
    justify-content: right;
}
#navbarNavDropdown .dropdown-menu {
    min-width: auto;
}
#navbarNavDropdown .dropdown-item {
    padding: .25rem 1rem;
}